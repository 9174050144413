/* Reset básico */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f4f7f9;
  font-family: Arial, sans-serif;
  color: #333;
}

/* Container principal */
.app-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
}

/* Cabeçalho */
header h1 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #444;
}

/* Card de conteúdo */
.card {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Seção de idioma */
.language-selector {
  margin-bottom: 1.5rem;
  text-align: center;
}

.language-selector select {
  margin-left: 0.5rem;
  padding: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Opções de envio */
.upload-options {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.upload-options .option {
  flex: 1 1 250px;
  background-color: #fafafa;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.upload-options .option h4 {
  margin-bottom: 0.5rem;
  color: #555;
}

.upload-options input[type="file"],
.upload-options input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.upload-options button {
  margin-top: 0.5rem;
  padding: 0.6rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-options button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

/* Controles de gravação */
.record-controls button {
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.record-controls button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

/* Botão de envio */
.send-button {
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 4px;
  background-color: #17a2b8;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1.5rem;
}

.send-button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

/* Seção de status */
.status-section {
  text-align: center;
}

.status-text {
  margin-bottom: 0.5rem;
}

.progress-bar-container {
  width: 100%;
  background-color: #e9ecef;
  border-radius: 4px;
  overflow: hidden;
  margin: 0.5rem 0;
}

.progress-bar {
  height: 10px;
  background-color: #007bff;
  transition: width 0.5s;
}

.progress-text {
  margin-bottom: 1rem;
}

/* Resultado */
.result-container {
  background-color: #f1f3f5;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 4px;
  margin-top: 1rem;
}
